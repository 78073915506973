import React from 'react';
import { useQuery } from 'react-query';
import { callApiWithResult } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';


export default function NewNotebookModal({ isActive, closeModalFn, title}) {
    const apiUrl = "lake/browser/create_notebook";
    const { isLoading, isError, data, error } = useQuery(
      [apiUrl, {}],
      async () => {
        const result = await callApiWithResult(apiUrl);
        return result.data
      },
      { enabled: isActive },
    )

    return (
      <div className={isActive ? 'modal is-active' : 'modal'}>
        <div className='modal-background' />
        <div className="modal-card" style={{width: "60vw", height: "30vh", margin: "15px", "padding-top": "30px", "padding-top": "30px"}}>
          <div className="modal-card-body">
              <h3 className='is-size-4'>{title}</h3>
              {
                isLoading ?
                  (<div className="iframe-holder" />)
                  :
                  (<div>
                    <p>
                    Notebook created: {data}
                    </p>
                    <button className='button' onClick={() => window.open(data, '_blank')}>Open</button>
                  </div>)
              }
            </div>
          </div>
        <button className='modal-close is-large' aria-label='close' onClick={closeModalFn} style={{marginTop: '80px'}}></button>
      </div>
    )
  }

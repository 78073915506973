import React, { Component } from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import LabNotebook from '@tra-sg/gatsby-theme-c360-portal/src/components/LabNotebook';
import MenuBar from '@tra-sg/gatsby-theme-c360-portal/src/components/MenuBar';
import LabModelExperiment from '@tra-sg/gatsby-theme-c360-portal/src/components/LabGallery/LabModelExperiment';
import CreateModelExperiment from '@tra-sg/gatsby-theme-c360-portal/src/components/LabGallery/CreateModelExperiment';
import { ClipLoader } from 'react-spinners';
import queryString from 'query-string';
import NewNotebookModal from '@tra-sg/gatsby-theme-c360-portal/src/components/LabGallery/NewNotebookModal';


export default class LabGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
        atCreateModel: false,
        showModel: true,
        showNotebook: true,
        notebookOnly: false,
        newNotebookModalActive: false,
    }
    this.toggleCreateModel = this.toggleCreateModel.bind(this)
  }

  componentDidMount() {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const queryToken = url.split("?");
    if (queryToken.length > 1) {
      const pageQuery = queryString.parse(queryToken[1]);
      console.log("PAGEQUERY", pageQuery)
      if (pageQuery.task == "experiment") {
        console.log("task experiment on entry, changing")
        this.toggleCreateModel();
      } else if (pageQuery.notebook_name) {
        console.log("showing notebook only")
        this.setState({notebookOnly: true});
      }
    }
  }

  toggleCreateModel() {
      let { atCreateModel } = this.state;
      this.setState({ atCreateModel: !atCreateModel});
  }

  renderOverviewBody() {
    const { showModel, showNotebook, notebookOnly, newNotebookModalActive} = this.state;

    if (notebookOnly) {
      return (<LabNotebook />)
    }

    return (
        <div>
          <div className='container'>
            <div className="level">
              <div className="level-left"></div>
              <div className="level-right">
                <div className='level-item'>
                  <p>Filter:</p>
                </div>
                <div className='level-item'>
                  <input
                    type="checkbox"
                    id="show-models"
                    name="show-models"
                    value="Show Models"
                    checked={showModel}
                    onChange={() => {this.setState({ showModel: !showModel})}}
                  />
                  <p>Show Models</p>
                </div>
                <div className='level-item'>
                  <input
                    type="checkbox"
                    id="show-models"
                    name="show-models"
                    value="Show Models"
                    checked={showNotebook}
                    onChange={() => {this.setState({ showNotebook: !showNotebook})}}
                  />
                  <p>Show Notebooks</p>
                </div>
              </div>
            </div>
          </div>
          { showModel ? (<LabModelExperiment handleCreate={this.toggleCreateModel}/>) : ""}
          <div className="container"><hr/></div>
          <div className="container">
            <p className="title is-4">Notebooks</p>
            <div className="level">
                <div className="level-left"></div>
                <div className="level-right">
                <div className="level-item">
                    <a onClick={() => this.setState({newNotebookModalActive: true})} className="button">New Notebook</a>
                    <NewNotebookModal
                      title="New Notebook"
                      isActive={newNotebookModalActive}
                      closeModalFn={() => this.setState({newNotebookModalActive: false})}
                      />
                </div>
                </div>
            </div>
            { showNotebook ? (<LabNotebook />) : ""}
          </div>
        </div>
    )
  }

  renderCreateModelBody() {
    return (
        <div className="container">
            <CreateModelExperiment />
        </div>)
  }

  render() {
    const { atCreateModel } = this.state;

    if (atCreateModel) {
      return this.renderCreateModelBody()
    } else {
      return this.renderOverviewBody()
    }
  }
}

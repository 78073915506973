import { callApiWithResult } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';
import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import {
  PieChart, Pie, Cell,
  BarChart, Bar,
  XAxis, YAxis,
  Tooltip, ResponsiveContainer,
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import queryString from 'query-string';
import { navigate } from 'gatsby';
import LabTablePreview from '@tra-sg/gatsby-theme-c360-portal/src/components/LabGallery/LabTablePreview';


function TableSelectSingle(props) {

  const [dataset, setDataset] = useState(props.dataset);
  const [zone, setZone] = useState(props.zone);
  const [table, setTable] = useState(props.table);


  return (
    <div className="field is-horizontal">
      <div className="field-body">
        <div className="field" disabled={props.disabled}>
          <label className="subtitle is-6">Dataset</label>
          <input
            className="input"
            type="text"
            placeholder="Dataset"
            value={dataset}
            disabled={props.disabled}
            onChange={e => {
              setDataset(e.target.value);
              if (props.setMainDataset) props.setMainDataset(e.target.value);
            }}
          />
        </div>
        {/* <div className="field">
          <label className="subtitle is-6">Zone</label>
          <input
            className="input"
            type="text"
            placeholder="Zone"
            value={zone}
            disabled={props.disabled}
            onChange={e => {
              setZone(e.target.value);
              if (props.setMainZone) props.setMainZone(e.target.value);
            }}
          />
        </div> */}
        <div className="field">
          <label className="subtitle is-6">Table</label>
          <input
            className="input"
            type="text"
            placeholder="Table"
            value={table}
            disabled={props.disabled}
            onChange={e => {
              setTable(e.target.value);
              if (props.setMainTable) props.setMainTable(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  )
}


// function TablePreviewInML(props) {
//   const { table_id, dataset_id, zone_id, group_id } = props;
//   const table_id_underscore = table_id.replace(/\./g,'_')
//   var groupstring = '';
//   if (group_id) {
//     if (group_id != 'common') groupstring = `&groups=${group_id}`;
//   }
//   const { isLoading, isError, data, error } = useQuery(
//       [`lake/table/${table_id_underscore}?dataset=${dataset_id}&zone=${zone_id}&limit=10`, {}],
//       async () => await callApiWithResult(`lake/table/${table_id_underscore}?dataset=${dataset_id}&zone=${zone_id}${groupstring}&limit=10`)
//   )

//   if (isError) {
//     if (error.message.includes('404')) return "Table preview is not available.";
//     return error.message;
//   }

//   if (isLoading) {
//     return (
//       <div className="columns is-centered">
//         <div className="column has-text-centered is-10">
//           <div className="iframe-holder" />
//         </div>
//       </div>
//     );
//   }

//   var usedSchema = null;
//   if (usedSchema == null && data.data) {
//     usedSchema = Object.keys(data.data[0]);
//     // if (setSchemaState) setSchemaState(usedSchema);
//   }
//   console.log("ML USEDSCHEMA", usedSchema);

//   if (usedSchema == null) return null;

//   let headerTitleDiv = []
//   usedSchema.forEach((column) => {
//     return (
//       headerTitleDiv.push(<th>{column}</th>)
//     )
//   })

//   console.log("MLTab data", data, isLoading, isError);
//   console.log("MLTab data breakdown", data, data.map);

//   let tableBodyDiv = []
//   let tableBodyRow = []
//   data.data.map(row => {
//     tableBodyRow = []
//     usedSchema.forEach((column) => {
//       tableBodyRow.push(<td>{row[column]}</td>)
//     })
//     tableBodyDiv.push(
//       <tr>
//         { tableBodyRow }
//       </tr>
//     )
//   })

//   return (
//     <table className="table is-bordered is-fullwidth is-size-7">
//       <thead>
//         <tr>
//           { headerTitleDiv }
//         </tr>
//       </thead>
//       <tbody>
//           { tableBodyDiv }
//       </tbody>
//     </table>
//   );
// }

function ModelResultItem(props) {
  const { name, accuracy, selected, onClick } = props;

  if (selected) {
    return (
      <a className={"panel-block is-active is-info is-bold"} onClick={onClick}>
        <span><strong>{name + ' '}</strong> ({accuracy}%)</span>
      </a>
    )
  } else {
    return (
      <a className={"panel-block"} onClick={onClick}>
         {name} ({accuracy}%)
      </a>
    )
  }
}

function ModelResultPanel(props) {
  const { data, onSelectModel } = props;

  const [selectedModel, setSelectedModel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log('This will run after 1 second!');
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className="columns is-centered">
        <div className="column has-text-centered is-10">
          <div className="iframe-holder" />
        </div>
      </div>
    );
  }

  function selectModel(model) {
    setSelectedModel(model);
    if (onSelectModel) onSelectModel(model);
  }

  return (
    <div className="panel">
      <div className="panel-heading">
        Result
      </div>
      { data.model_list.map((e) => (
          <ModelResultItem
            name={e.name}
            accuracy={e.eval ? e.eval.Accuracy * 100 : e.accuracy} // todo: standardize e.accuracy
            selected={(e.name == selectedModel)}
            onClick={() => selectModel(e.name)}
          />
        )) }
    </div>
  )
}

function ModelResultDetail(props) {
  const { model_data, selectedModel, saveDisabled } = props;

  const { isLoading, isError, data, error } = useQuery(
    [`model/${selectedModel.toLowerCase()}/plots`, {}],
    async () => await callApiWithResult(`model/${selectedModel.toLowerCase()}/plots`)
  )

  if (model_data == null) return "";

  if (isLoading) {
    return (
      <div className="columns is-centered">
        <div className="column has-text-centered is-10">
          <div className="iframe-holder" />
        </div>
      </div>
    );
  }

  console.log("PLOT QUERY", `model/${selectedModel.toLowerCase()}/plots`)
  console.log("PLOT DATA", data)
  console.log("PLOT ERROR", error)

  if (error) return (<div>{error}</div>);

  var dataSelected;
  if (model_data) {
    model_data.model_list.forEach((e) => {
      if (e.name == selectedModel) {
        dataSelected = e;
      }
    })
  }

  if(data) {
    if(data.plot_urls.length > 0) {
      return (
        <div>
          <div>
            <p className='is-size-5'>Model Plots</p>
            <hr/>
          </div>
          <div className="columns is-multiline">
            {/* <ModelAccuracyRadial data={dataSelected}/> */}
            {
              data.plot_urls.map(url => (
                <div className="column is-one-third">
                  <img src={url} />
                </div>
              ))
            }
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div>
            <p className='is-size-5'>Model Plots</p>
            <hr/>
            <p className='is-italic'>No plots found for this model.</p>
          </div>
        </div>
      )
    }
  }

  return (<div></div>);


  // return (
  //   <div>
  //     <div>
  //       {/* Model selected: {selectedModel} */}
  //       <div className="columns">
  //         { dataSelected ? <ModelAccuracyRadial data={dataSelected}/> : ""}
  //         { dataSelected ? <ModelConfusionMatrix data={dataSelected}/> : ""}
  //         { dataSelected ? <ModelFeatureImportance data={dataSelected}/> : ""}
  //       </div>
  //       { dataSelected && !saveDisabled ? <ModelSaveDialogue /> : ""}
  //     </div>
  //   </div>
  // )
}

function getColor(index) {
  const offset = 0;
  const colorWheel = [
    "#d11141",
    "#00b159",
    "#00aedb",
    "#f37735",
    "#ffc425",
  ]

  let colorIndex = (index + offset) % colorWheel.length;
  return colorWheel[colorIndex];
}

function ModelAccuracyRadial(props) {
  const { data } = props;
  console.log("Data in radial", data)

  const pieData = [
    { name: "Negative", portion: 100 - data.accuracy },
    { name: "Positive", portion: data.accuracy },
  ]

  return (
    <div className="column is-one-quarter">
      <p className="title is-5">Accuracy: {data.accuracy}%</p>
      <div style={{width: "100%", height: 220}}>
        <ResponsiveContainer>
          <PieChart>
            <Pie data={pieData} dataKey="portion" nameKey="name" cx="50%" cy="50%" outerRadius={100} fill="#8884d8" >
              {
                pieData.map((entry, c_index) => (
                  <Cell
                    key={`pie-accuracy-cell-${c_index}`}
                    fill={getColor(c_index)}
                  />
                ))
              }
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

function ModelFeatureImportance(props) {
  const { data } = props;

  const featureData =[
    { name: "Sex_male", importance: 76 },
    { name: "Age", importance: 72 },
    { name: "Title_Master", importance: 68 },
    { name: "Family Size_Very Big", importance: 64 },
    { name: "PClass_1", importance: 54 },
    // { name: "Family Size_Solo", importance: 50 },
    // { name: "PClass_3", importance: 46 },
  ]

  return (
    <div className="column is-half">
      <p className="title is-5">Feature Importance</p>
      <div style={{width: "100%", height: 220}}>
        <ResponsiveContainer>
          <BarChart
            data={featureData}
            layout="vertical"
          >
            <XAxis type="number" hide />
            <YAxis type="category" width={150} padding={{ left: 20 }} dataKey="name"/>
            <Bar dataKey="importance">
              {
                featureData.map((entry, c_index) => (
                  <Cell
                    key={`pie-accuracy-cell-${c_index}`}
                    fill={getColor(c_index)}
                  />
                ))
              }
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}


function ModelSaveDialogue(props) {

  const [modalShowing, setModalShowing] = useState(false);

  return (
    <div>
      <div className="level">
        <div className="level-left"></div>
        <div className="level-right">
          <div className="level-item">
          <a className="button" onClick={() => setModalShowing(true)}>Save Result</a>
          </div>
        </div>
      </div>
      <div className={modalShowing ? "modal is-active" : "modal"}>
        <div className="modal-background"></div>
        <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Save Model Experiment</p>
          <button className="delete" aria-label="close" onClick={() => setModalShowing(false)}></button>
        </header>
        <section className="modal-card-body">
          <div className="field is-horizontal">
            {/* <div className="field-label is-normal">
              <label className="label" style={{color: "black !important"}}>Name</label>
            </div> */}
            <div className="field-body">
              <div className="field">
                <input
                  className="input"
                  type="text"
                  placeholder="Experiment Name"
                />
              </div>
            </div>
          </div>
          <textarea class="textarea" placeholder="e.g. Model that predicts survival"></textarea>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={() => setModalShowing(false)}>Save changes</button>
          <button className="button" onClick={() => setModalShowing(false)}>Cancel</button>
        </footer>
        </div>

      </div>
    </div>
  )
}

function ModelConfusionMatrix(props) {
  const { data } = props;

  return (
    <div className="column is-one-quarter" style={{height: "100%"}}>
      <p className="title is-5">Confusion Matrix</p>
      <div className="tile is-ancestor" style={{height: "100%"}}>
        <div className="tile is-vertical">
          <div className="tile is-parent">
            <div className="tile is-child notification is-success">
              <p className="title">29%</p>
              <p className="subtitle is-size-7">True Positive</p>
            </div>
          </div>
          <div className="tile is-parent">
            <div className="tile is-child notification is-warning">
              <p className="title">10%</p>
              <p className="subtitle is-size-7">False Negative</p>
            </div>
          </div>
        </div>
        <div className="tile is-vertical">
          <div className="tile is-parent">
            <div className="tile is-child notification is-warning">
              <p className="title">3%</p>
              <p className="subtitle is-size-7">False Positive</p>
            </div>
          </div>
          <div className="tile is-parent">
            <div className="tile is-child notification is-success">
              <p className="title">58%</p>
              <p className="subtitle is-size-7">True Negative</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function CreateModelExperiment(props) {

  var { resultOnly, model_data, status } = props;

  function getQueryParam() {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const queryToken = url.split("?");

    const appendUsers = (group_id) => {
      if(group_id == "common") return group_id;
      return ["users", group_id]
    }

    if (queryToken.length > 1) {
      const pageQuery = queryString.parse(queryToken[1]);
      // handle datasource
      try {
        const dataSourceTokens = pageQuery.data_source.split(".");
        pageQuery['dataset'] = dataSourceTokens[0];
        pageQuery['table'] = dataSourceTokens[1];
        pageQuery["groups"] = pageQuery.group_id ? appendUsers(pageQuery.group_id) : null;
        console.log("EXP PAGE QUERY", pageQuery);
        return pageQuery;
      } catch {
        console.log("DATA SOURCE NOT IN CORRECT FORMAT")
      }
    }

    return {};
  }

  const pageQuery = getQueryParam();
  const [experimentName, setExperimentName] = useState(props.experimentName || model_data ? model_data.name : "");
  const [target, setTarget] = useState(props.label || "");
  const [mainDataset, setMainDataset] = useState(pageQuery.dataset || props.dataset || "emission");
  const [mainZone, setMainZone] = useState(pageQuery.zone || props.zone || "derived_restricted");
  const [mainTable, setMainTable] = useState(pageQuery.table || props.table || "t360_carbon_overview");
  const [mainGroup, setMainGroup] = useState(pageQuery.groups || props.group || "");
  const [extraTableCount, setExtraTableCount] = useState(0);
  const [modelIsTraining, setModelIsTraining] = useState(false);
  const [tableColumns, setTableColumns] = useState(null);

  // A state for model data that starts with the given model_data props
  const [stateModelData, setStateModelData] = useState(model_data);

  const [modelTrained, setModelTrained] = useState(status == "SUCCESS");
  const [selectedModel, setSelectedModel] = useState("");
  const startExperimentMutation = useMutation(
    payload => {
      const fetchOptions = {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' },
      }
      return callApiWithResult(`model/exp_train/`, fetchOptions);
    }, {
      onSuccess: (data, variables, context) => {
        // pool get request
        // useQuery(
        //     [`lake/table/${table_id}?dataset=${dataset_id}&zone=${zone_id}&limit=10`, {}],
        //     async () => await callApiWithResult(`lake/table/${table_id}?dataset=${dataset_id}&zone=${zone_id}&limit=10`)
        // )
        setModelIsTraining(false);
        navigate(`/labs/experiment/${experimentName}`)
      },
    }
  )

  const onClickStartExperiment = () => {

    // field verification
    if (!experimentName.match(/^[0-9a-z\-\_]+$/)) {
      alert(`Invalid experiment name '${experimentName}'. Experiment name can only be alphanumeric with underscore.`);
      return;
    }

    if (!tableColumns.includes(target)) {
      alert(`Column '${target}' is not in the table.`);
      return;
    }

    setModelIsTraining(true);
    const payload = {
        "data_source": {
            "dataset": mainDataset,
            "zone": mainZone,
            "table": mainTable,
            "groups": ["users", "ghosalya"],
        },
        "model_type": "CLASSIFICATION",
        "label": target,
        "model_name": experimentName,
        "description": "Predicting Titanic survivor.",
    }
    startExperimentMutation.mutate(payload);
  }

  const data = [
    { name: "LightGBM", accuracy: 87 },
    { name: "Ada Boost", accuracy: 83 },
    { name: "Decision Tree", accuracy: 82 },
  ]

  if (model_data == null) model_data = data;
  console.log("model_data", model_data)

  return (
    <div>
      <div className="columns">
        <div className="column is-two-thirds">
          <p className="is-size-5">Data Source</p>
          <hr/>
          <TableSelectSingle
            dataset={mainDataset}
            zone={mainZone}
            table={mainTable}
            setMainDataset={setMainDataset}
            setMainZone={setMainZone}
            setMainTable={setMainTable}
            disabled={resultOnly}
          />
          { new Array(extraTableCount).fill(<TableSelectSingle />)}
          {resultOnly ? "" : (<div className="level">
            <div className="level-left"></div>
            <div className="level-right">
              <div className="level-item">
                <a className="button" onClick={() => setExtraTableCount(extraTableCount + 1)}>+</a>
              </div>
            </div>
          </div>)}
          {/* <p className="is-size-5">Main Table Preview</p> */}
          <LabTablePreview
            dataset_id={mainDataset} table_id={mainTable} zone_id={mainZone} group_id={mainGroup}
            setTableColumns={setTableColumns}
          />
        </div>
        <div className="column is-one-third">
          <p className="is-size-5">Experiment</p>
          <hr/>
          <div className="field">
            <p className="control">
              <label className="subtitle is-6">Experiment Name</label>
              <input id="Label" className="input" type="text" value={experimentName} onChange={e => setExperimentName(e.target.value)} disabled={resultOnly}/>
            </p>
            <p className="control">
              <label className="subtitle is-6">Target</label>
              <input id="Label" className="input" type="text" value={target} onChange={e => setTarget(e.target.value)} disabled={resultOnly}/>
            </p>
            {resultOnly ? "" : (<div className="has-text-centered">
              <a
              className="button is-info" style={{width: '100%'}} onClick={onClickStartExperiment}
              >Start Experiment</a>
            </div>)}
            {modelIsTraining ? (<div className="iframe-holder" />) : ""}
          </div>
          {modelTrained ? <ModelResultPanel data={stateModelData} onSelectModel={setSelectedModel}/> : ""}
        </div>
      </div>
      <br/>
      <div>
        {selectedModel ? (<ModelResultDetail model_data={stateModelData} selectedModel={selectedModel} saveDisabled={resultOnly} />) : ""}
      </div>
  </div>
  )
}
